<template>
  <v-card flat class="mt-2">
    <form-data :references.sync="formReferences" :model="hourObj" ref="normalHourReference">
      <template slot="section-top">
      <!-- <Slider :timeline="timeline"></Slider> -->
        <!-- <v-range-slider ref="hoursRangeSlider" :tick-labels="sliderOptions.sliderLabels" :value="sliderOptions.value" :min="sliderOptions.min" :max="sliderOptions.max" readonly dense tick-size="3"></v-range-slider> -->
      </template>
    </form-data>
  </v-card>
</template>
<script>

import FormTemplate from '@/components/FormTemplate'
import { mapGetters } from 'vuex'
import moment from 'moment'
import CONSTANTS from '@/assets/json/constants.json'
import projectMixin from '@/mixins/projectMixin'
export default {
  mixins: [projectMixin],
  props: ['hourObj', 'listItems', 'isWeekLocked', 'backLink'], /* 'sliderOptions', */
  data () {
    return {
      HOURS: 'hours',
      userObj: this.$store.state.auth.userDetails,
      listOfbreaks: [{ val: 0 }, { val: 15 }, { val: 30 }, { val: 45 }, { val: 60 }],
      listOfActivities: [],
      isValidTime: true,
      canWatchShiftId: false,
      EditAmountValue: null,
      time: null
    }
  },
  components: {
    'form-data': FormTemplate
  },
  computed: {
    ...mapGetters(['formType', 'generalSettings', 'getTimerObj', 'getuserDetails']),
    formReferences () {
      return {
        properties: [{
          model: 'date',
          type: this.formType.DATEPICKER,
          rules: this.$_requiredValidation,
          picker: 'dateStringPicker',
          menu: 'dateStringMenu',
          color: 'primary lighten-1',
          label: this.$t('message.common.date'),
          disabled: this.hourObj.id ? this.isWeekLocked || this.isReadOnly : false,
          class: 'xs6 sm4 md3 lg3'
        }, {
          model: 'total',
          type: this.formType.TEXT,
          rules: [],
          label: this.$t('message.common.total'),
          class: 'xs6 sm4 md3 lg3',
          disabled: true
        }, {
          model: 'project_id',
          type: this.formType.AUTO_COMPLETE,
          rules: this.$_requiredValidation,
          items: this.listItems.listOfProjects,
          select_text: (item) => this.setProjectname(item),
          select_value: 'id',
          disabled: this.isWeekLocked || this.isReadOnly,
          label: this.$t('message.layout.projects'),
          class: 'xs12 sm4 md3 lg3',
          is_show: this.$store.state.common.isProjectExcluded ? this.setDefaultProject() : true
        }, {
          model: 'shift_id',
          type: this.formType.SELECT,
          rules: this.$_requiredValidation,
          items: this.listItems.listOfShifts,
          select_text: (val) => this.$i18n.locale === 'en' ? `${val[`${this.$i18n.locale}_name`]}` : val.no_name,
          select_value: 'id',
          disabled: this.isWeekLocked || this.isReadOnly,
          label: this.$t('message.timer.shift'),
          class: ' xs12 sm4 md3 lg3',
          // is_show: this.generalSettings.use_shift,
          click: this.setWatchForShiftId
        }, {
          model: 'amount',
          type: this.formType.TEXT,
          rules: this.hourObj.amount ? this.$_HourValidation : this.$_requiredValidation,
          label: this.$t('message.timer.hours'),
          class: 'xs6 sm4 md3 lg3',
          disabled: this.isWeekLocked || this.isReadOnly,
          is_show: this.generalSettings.hour_enter_style === 2 && !this.generalSettings.use_shift
        }, {
          model: 'starttime',
          // max: 'endtime',
          type: this.formType.TIMEPICKER,
          rules: this.$_requiredValidation,
          menu: 'startTimeMenu',
          label: this.$t('message.timer.from'),
          disabled: this.isWeekLocked || this.isReadOnly,
          class: 'xs6 sm4 md3 lg3',
          is_show: this.generalSettings.hour_enter_style === 1 || this.generalSettings.use_shift
        }, {
          model: 'endtime',
          min: 'starttime',
          type: this.formType.TIMEPICKER,
          rules: this.$_requiredValidation,
          menu: 'endTimeMenu',
          label: this.$t('message.timer.till'),
          disabled: this.isWeekLocked || this.isReadOnly,
          class: 'xs6 sm4 md3 lg3',
          is_show: this.generalSettings.hour_enter_style === 1 || this.generalSettings.use_shift
        }, {
          model: 'break_hr',
          type: this.formType.SELECT,
          rules: [], // this.$_requiredValidation,
          items: this.listOfbreaks,
          select_text: (item) => item.val + ` ${this.$t('message.timer.minutes')}`,
          select_value: 'val',
          disabled: this.isWeekLocked || this.isReadOnly,
          label: this.$t('message.timer.pause'),
          class: `sm4 md3 lg3 ${this.generalSettings.hour_enter_style === 1 || this.generalSettings.use_shift ? 'xs12' : 'xs6'}`
        }, {
          model: 'activity_id',
          type: this.formType.SELECT,
          rules: this.$store.state.common.isProjectExcluded ? [] : this.$_requiredValidation,
          items: this.listOfActivities,
          select_text: 'name',
          select_value: 'id',
          disabled: this.isWeekLocked || this.isReadOnly,
          label: this.$t('message.timer.activity'),
          class: 'xs12 sm4 md3 lg3'
        }, {
          model: 'work_id',
          type: this.formType.SELECT,
          rules: this.$_requiredValidation,
          items: this.listItems.workTypes.filter(x => x.is_billable),
          select_text: (val) => this.$i18n.locale === 'en' ? `${val[`${this.$i18n.locale}_name`]}` : val.no_name,
          select_value: 'id',
          disabled: this.isWeekLocked || this.isReadOnly,
          label: this.$t('message.common.worktype'),
          class: 'xs12 sm4 md3 lg3'
        }, {
          model: 'location',
          type: this.formType.TEXT,
          rules: [],
          disabled: this.isWeekLocked || this.isReadOnly,
          label: this.$t('message.timer.place'),
          class: 'xs12 sm4 md3 lg3'
        }, {
          model: 'comments',
          type: this.formType.TEXTAREA,
          rules: this.$_requiredValidation,
          disabled: this.isWeekLocked || this.isReadOnly,
          label: this.$t('message.common.comments'),
          class: 'xs12 sm4 md6 lg6',
          rows: 3
        }, {
          model: 'not_approved_comment',
          type: this.formType.TEXTAREA,
          rules: [],
          // eslint-disable-next-line
          is_show: this.hourObj.state === CONSTANTS.NOT_APPROVED,
          readonly: true,
          label: this.$t('message.timer.notApproved'),
          class: 'xs12 sm4 md6 lg6 custom_opacity_reduced',
          rows: 3
        }],
        buttons: [{
          name: 'action_handler',
          color: 'success',
          label: this.hourObj.id ? this.$t('message.common.update') : this.$t('message.common.save'),
          click: this.saveUpdateHandler,
          disabled: this.isWeekLocked || this.isReadOnly,
          loading: this.loading,
          is_show: true
        }, {
          name: 'back',
          color: 'info',
          click: () => this.$router.go(-1), // this.$router.push(this.backLink),
          label: this.$t('message.login.back'),
          to: this.backLink,
          is_show: true
        }, {
          name: 'delete',
          color: 'error',
          click: this.deleteHandler,
          label: this.$t('message.common.delete'),
          is_show: this.hourObj.id && !this.isWeekLocked && !this.isReadOnly
        }]
      }
    },
    isReadOnly () {
      return this.hourObj.state === CONSTANTS.DELIVERED || this.hourObj.state === CONSTANTS.APPROVED
    }
  },
  mounted () {
    const lastShiftUsed = localStorage.getItem(`lastShiftUsed_${this.getuserDetails.id}`)
    const result = this.listItems.listOfShifts.find(x => x.is_default)
    if (lastShiftUsed) {
      this.hourObj.shift_id = Number(lastShiftUsed)
    } else if (result) {
      this.hourObj.shift_id = result.id
    } else {
      this.hourObj.shift_id = this.listItems.listOfShifts[0].id
    }
    setTimeout(() => {
      this.EditAmountValue = this.hourObj ? this.hourObj.amount : null
    }, 100)
    if (this.hourObj.date) this.callDateChangeMethods()
    if (this.hourObj.project_id) this.getActivitiesForProject(this.hourObj.project_id)
    this.$eventBus.$on('confirmedToProceed', (data, saveData) => {
      if (data === 'hours') {
        this.$store.commit('hideConfirmationDialog')
        this.proceedToSaveUpdateHandler(saveData.url, saveData.model)
      }
    })
  },
  methods: {
    getCurrentDayHours () {
      const startDate = this.$formatter.formatDate(this.hourObj.date, 'DD.MM.YYYY', 'MM.DD.YYYY')
      const endDate = this.$formatter.formatDate(this.hourObj.date, 'DD.MM.YYYY', 'MM.DD.YYYY 23:59:59')
      const model = { start: startDate, end: endDate, users: [this.userObj ? this.userObj.id : 0] }
      model.showAllProjects = true
      this.$api.execute('post', 'hours/filter', model).then((response) => {
        if (response.data && response.data.length > 0) {
          if (this.hourObj.id) response.data = response.data.filter(x => x.id !== this.hourObj.id)
          var dates = response.data.map((hour) => {
            return moment(hour.end_date, 'DD.MM.YYYY HH:mm:ss')
          })
          this.generalSettings.standard_start_time = moment.max(dates).format('HH:mm:ss')
        } else this.generalSettings.standard_start_time = this.$store.state.common.standardStartTime
      })
    },
    getActivitiesForProject (id) {
      this.$api.execute('get', `activities/get_by_project/${id}`)
        .then(response => {
          this.listOfActivities = this.$formatter.cloneVariable(response.data)
        })
    },
    saveUpdateHandler () {
      if (this.$refs.normalHourReference.$refs.validateForm.validate()) {
        const model = this.$formatter.cloneVariable(this.hourObj)
        model.state = model.id ? model.state !== CONSTANTS.NOT_APPROVED ? model.state : CONSTANTS.NOT_DELIVERED : CONSTANTS.NOT_DELIVERED
        /* adding conditions from timer  */
        let fieldsCondition = false
        let shiftCondition = false
        if (this.generalSettings.use_shift) {
          shiftCondition = this.hourObj.shift_id !== ''
          fieldsCondition = true
        }
        if (this.generalSettings.hour_enter_style === 1 && !this.generalSettings.use_shift) {
          fieldsCondition = this.hourObj.starttime !== '' && this.hourObj.endtime !== ''
          shiftCondition = true
        } else if (this.generalSettings.hour_enter_style === 2 && !this.generalSettings.use_shift) {
          fieldsCondition = this.hourObj.amount && this.hourObj.amount.toString().replace(',', '.') > 0
          shiftCondition = true
        }
        if (fieldsCondition && shiftCondition) {
          let getStartDate = ''
          let getEndDate = ''
          let runningShiftTime = ''
          let totalrunningShiftTime = ''
          if (this.generalSettings.hour_enter_style === 1 || (this.generalSettings.hour_enter_style === 2 && this.generalSettings.use_shift)) {
            getStartDate = `${this.hourObj.date} ${this.hourObj.starttime}`
            getEndDate = `${this.hourObj.date} ${this.hourObj.endtime}`
            // const shift = this.listItems.listOfShifts.find(x => x.id === this.hourObj.shift_id)
            const shiftTime = moment(this.hourObj.endtime, 'hh:mm').diff(moment(this.hourObj.starttime, 'hh:mm'))
            const totalShiftTime = Number(moment.duration(shiftTime).hours()) + '.' + Number(moment.duration(shiftTime).minutes())
            const totalNormalValue = this.hourObj.total.toString().split('(')[0].replace(',', '.').trim()
            const totalAbsenceValue = this.hourObj.total.toString().split('(')[1].split(')')[0].replace(',', '.')
            const runningShift = this.listItems.listOfShifts.find(x => x.id === (this.getTimerObj ? Number(this.getTimerObj.shift_id) : 0))
            if (runningShift) {
              runningShiftTime = moment(runningShift.time_till, 'hh:mm').diff(moment(runningShift.time_from, 'hh:mm'))
              totalrunningShiftTime = Number(moment.duration(runningShiftTime).hours()) + '.' + Number(moment.duration(runningShiftTime).minutes())
            }
            if (!this.hourObj.id) {
              (totalrunningShiftTime ? Number(totalrunningShiftTime) : 0) + Number(totalShiftTime) + Number(totalNormalValue) + Number(totalAbsenceValue) > 24 ? this.isValidTime = false : this.isValidTime = true
            } else {
              Number(totalShiftTime) + (Number(totalNormalValue) - Number(this.hourObj.amount.replace(',', '.').trim())) + Number(totalAbsenceValue) > 24 ? this.isValidTime = false : this.isValidTime = true
            }
            // this.isValidTime = true
          } else if (this.generalSettings.hour_enter_style === 2 && !this.generalSettings.use_shift) {
            const startTime = this.generalSettings.standard_start_time
            const calEndTime = moment(startTime, 'HH:mm').add(this.hourObj.amount.toString().replace(',', '.'), 'hours').format('HH:mm:ss')
            getStartDate = `${this.hourObj.date} ${startTime}`
            getEndDate = `${this.hourObj.date} ${calEndTime}`
            const totalAbsenceValue = this.hourObj.total.toString().split('(')[1].split(')')[0].replace(',', '.')
            const totalNormalValue = this.hourObj.total.toString().split('(')[0].replace(',', '.')
            const totalEnteredHours = model.total.toString().split(',')[0].replace(',', '.')
            if (!this.hourObj.id) Number(totalNormalValue) + Number(model.amount) + Number(totalAbsenceValue) > 24 ? this.isValidTime = false : this.isValidTime = true
            else (Number(totalEnteredHours) - Number(totalNormalValue)) + Number(model.amount) > 24 ? this.isValidTime = false : this.isValidTime = true

            // if (!this.hourObj.id) Number(this.hourObj.total.toString().split(',')[0]) + Number(model.amount) + Number(this.hourObj.total.toString().split('(')[1].split(',')[0]) >= 24 ? this.isValidTime = false : this.isValidTime = true
            // else (Number(model.total.toString().split(',')[0]) - Number(this.EditAmountValue.toString().split(',')[0])) + Number(model.amount) + Number(model.total.toString().split('(')[1].split(',')[0]) >= 24 ? this.isValidTime = false : this.isValidTime = true
            // moment.duration(this.generalSettings.standard_end_time).asHours() < moment.duration(calEndTime).asHours()
          }
          const startDate = moment(getStartDate, 'DD.MM.YYYY HH:mm')
          const endDate = moment(getEndDate, 'DD.MM.YYYY HH:mm')
          const duration = moment.duration(endDate.diff(startDate))
          const hours = parseFloat(duration.asHours()) % 60
          model.start_date = startDate.format('MM.DD.YYYY HH:mm')
          model.end_date = endDate.format('MM.DD.YYYY HH:mm')
          if (this.generalSettings.hour_enter_style === 1 || (this.generalSettings.hour_enter_style === 2 && this.generalSettings.use_shift)) {
            model.amount = hours
          } else if (this.generalSettings.hour_enter_style === 2 && !this.generalSettings.use_shift) {
            model.amount = this.hourObj.amount.toString().replace(',', '.')
          }
          model.break_hr = moment.duration(parseFloat(model.break_hr), 'minutes').asHours()
          model.user_id = this.userObj ? this.userObj.id : 0
          model.type = CONSTANTS.WORKING
          const url = this.HOURS
          if (model.amount > 0) {
            if (model.amount > model.break_hr) {
              localStorage.setItem(`lastShiftUsed_${model.user_id}`, model.shift_id)
              if (this.isValidTime) this.proceedToSaveUpdateHandler(url, model)
              else { // confirmAddExceedingHour
                this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.common.hourExceedNotification' })
              }
            } else {
              this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.shifts.notValidPausedTime' })
            }
          } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.common.seemsInvalid' })
          // const payload = { title: 'message.common.confirmAddHour', text: 'message.common.sureToAddExceedingHour', module: 'hours', saveData: { url, model } }
          // this.$store.commit('showConfirmationDialog', payload)
          /* if (this.isValidTime) {
          } else // this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.common.hourExceedNotification' }) */
        }
      } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.common.validationIssue' })
    },
    proceedToSaveUpdateHandler (url, model) {
      this.loading = true
      this.$api.saveUpdateHandler(url, model)
        .then((response) => {
          this.$root.$emit('snackbar', response.snackbar)
          this.$router.push('/calendar')
        })
        .finally(() => {
          this.loading = false
        })
    },
    callDateChangeMethods () {
      this.getCurrentDayHours()
      this.$eventBus.$emit('getactiveDateHourRecords', this.hourObj.date)
    },
    setPauseValueByTime () {
      if (this.hourObj.starttime && this.hourObj.endtime) {
        if (this.generalSettings.pause_setting_type === 1) {
          if (this.hourObj && !this.hourObj.break_hr) {
            this.hourObj.break_hr = this.getBreakHrValueByTime(this.hourObj.starttime, this.hourObj.endtime)
          }
        } else if (this.generalSettings.pause_setting_type === 2) {
          if (this.hourObj && !this.hourObj.break_hr) {
            const hour = moment(this.hourObj.endtime, 'HH:mm').diff(moment(this.hourObj.starttime, 'HH:mm'), 'hours', true)
            this.hourObj.break_hr = this.getBreakHrValueByHour(hour)
          }
        }
      }
    },
    setPauseValueByHour (val) {
      const total = this.$formatter.replaceCommaWithDot(val)
      if (total > 24) {
        this.hourObj.amount = 24
      }
      if (this.generalSettings.pause_setting_type === 2) {
        if (this.hourObj && !this.hourObj.break_hr) {
          this.hourObj.break_hr = this.getBreakHrValueByHour(total)
        }
      } else if (this.generalSettings.pause_setting_type === 1) {
        if (this.hourObj && !this.hourObj.break_hr) {
          const startTime = this.generalSettings.standard_start_time
          const calEndTime = moment(startTime, 'HH:mm').add(total, 'hours').format('HH:mm')
          this.hourObj.break_hr = this.getBreakHrValueByTime(startTime, calEndTime)
        }
      }
    },
    getBreakHrValueByTime (startTime, endTime) {
      const pauseSettings = this.listItems.pauseSettings
      let calculateTotalTime = 0
      for (let i = 0; i < pauseSettings.length; i++) {
        const from = moment(pauseSettings[i].time_from, 'HH:mm')
        const till = moment(pauseSettings[i].time_till, 'HH:mm')
        if (moment(startTime, 'HH:mm').isSameOrBefore(from) && moment(endTime, 'HH:mm').isSameOrAfter(till)) {
          var duration = moment.duration(till.diff(from))
          var hours = parseInt(duration.asHours())
          var minutes = parseInt(duration.asMinutes()) % 60
          if (hours > 0) calculateTotalTime = 60
          else calculateTotalTime += minutes
        }
      }
      calculateTotalTime = Math.abs(calculateTotalTime)
      calculateTotalTime = calculateTotalTime > 0 ? calculateTotalTime > 60 ? 60 : calculateTotalTime : 0
      if (![0, 15, 30, 45, 60].includes(calculateTotalTime)) {
        calculateTotalTime = this.$formatter.getNearestValueFromNumbers(calculateTotalTime, [0, 15, 30, 45, 60])
      }
      return calculateTotalTime
    },
    getBreakHrValueByHour (hour) {
      const pauseSettings = this.listItems.pauseSettings
      let calculateTotalBreak = 0
      const sortedArray = pauseSettings.sort(function (a, b) {
        return a.hours - b.hours
      })
      const hoursArray = sortedArray.reverse()
      for (let i = 0; i < hoursArray.length; i++) {
        if (hoursArray[i].hours <= hour) {
          calculateTotalBreak = hoursArray[i].break_hr
          break
        }
      }
      calculateTotalBreak = calculateTotalBreak ? calculateTotalBreak >= 60 ? 60 : calculateTotalBreak : 0
      if (![0, 15, 30, 45, 60].includes(calculateTotalBreak)) {
        calculateTotalBreak = this.$formatter.getNearestValueFromNumbers(calculateTotalBreak, [0, 15, 30, 45, 60])
      }
      return calculateTotalBreak
    },
    deleteHandler () {
      const payload = { url: `hours/${this.hourObj.id}`, module: 'hours', isSingle: true }
      this.$store.commit('showDeleteDialog', payload)
    },
    setDefaultProject () {
      const projects = this.listItems.listOfProjects
      const obj = projects && projects.length > 0 ? projects.find(x => x.number === 'TM001') : null
      if (obj) {
        this.hourObj.project_id = obj.id
      }
      return false
    },
    setWatchForShiftId () {
      this.canWatchShiftId = true
    }
  },
  watch: {
    'hourObj.dateStringPicker' (val) {
      if (val) {
        this.hourObj.date = this.$formatter.formatDate(val, 'YYYY-MM-DD', 'DD.MM.YYYY')
      }
    },
    'hourObj.date' (val) {
      if (val) {
        this.callDateChangeMethods()
      }
    },
    'hourObj.project_id' (val) {
      if (val) {
        this.getActivitiesForProject(val)
        this.getCurrentDayHours()
      }
    },
    'hourObj.starttime' (val) {
      if (val) this.setPauseValueByTime()
    },
    'hourObj.endtime' (val) {
      if (val) this.setPauseValueByTime()
    },
    'hourObj.amount' (val) {
      this.setPauseValueByHour(val) // if (val)
    },
    'hourObj.shift_id' (val) {
      if (val && this.canWatchShiftId) {
        const shiftsList = this.listItems.listOfShifts
        if (shiftsList) {
          const result = shiftsList.find(x => x.id === val)
          if (result) {
            if (!this.hourObj.starttime) {
              this.hourObj.starttime = moment(result.time_from, 'HH:mm:ss').format('HH:mm')
            }
            this.hourObj.endtime = moment(result.time_till, 'HH:mm:ss').format('HH:mm')
            if (this.hourObj.starttime === this.hourObj.endtime) {
              this.hourObj.endtime = moment(result.time_till, 'HH:mm:ss').add(2, 'hours').format('HH:mm')
            }
            this.hourObj.break_hr = result.break_hr
          }
        }
        this.canWatchShiftId = false
      }
    },
    'listItems.workTypes' (val) {
      if (val && !this.hourObj.id) {
        const obj = val.find(x => x.is_default && x.is_billable)
        if (obj) this.hourObj.work_id = obj.id
      }
    }
  },
  beforeDestroy () {
    this.$eventBus.$off('confirmedToProceed')
  }
}
</script>
<style>
</style>
